export class Environment{

    static get(){
        const environment =  {
            dev: {
                API_URL: 'http://localhost:9080/api/',
                // API_URL: 'https://app.fyre.tech/api/',
                //API_BASE: 'https://app.fyre.tech/',
                API_BASE: 'http://localhost:9080/',
                SOURCE: 'localhost:3000/',
                //SOURCE: 'https://onepay.fyre.tech/',
                PUBNUB_KEY: 'pub-c-0a36c13d-8172-48e0-ba00-1c2253c991b3',
                PUBNUB_SUBSCRIBE_KEY: 'sub-c-9a4af4b4-8a89-11e8-8b6c-9a2ed1c29e11',
                PUBNUB_SECRET_KEY: 'sec-c-YzVmNmU3YjktNjM1Mi00YjhiLWIwM2EtYjU5YzFiNDJlMzE5',
                PAYMENT_PROCESSOR: 'lyfpay',
                STRIPE_KEY: 'pk_test_NmpnJlNs3OlZBG5ErNkQBm1z',
                DESIGN: 'lyfpay'
            },
            prod: {
                API_URL: 'https://dev.lyfpay.express/api/',
                API_BASE: 'https://dev.lyfpay.express/',
                SOURCE: 'https://devfront.lyfpay.express/',
                PUBNUB_KEY: 'pub-c-0a36c13d-8172-48e0-ba00-1c2253c991b3',
                PUBNUB_SUBSCRIBE_KEY: 'sub-c-9a4af4b4-8a89-11e8-8b6c-9a2ed1c29e11',
                PUBNUB_SECRET_KEY: 'sec-c-YzVmNmU3YjktNjM1Mi00YjhiLWIwM2EtYjU5YzFiNDJlMzE5',
                PAYMENT_PROCESSOR: 'lyfpay',
                STRIPE_KEY: 'pk_test_NmpnJlNs3OlZBG5ErNkQBm1z',
                DESIGN: 'lyfpay'
            }
        };

        if(process.env.NODE_ENV === 'production'){
            return environment.prod;
        }

        return environment.dev;
    }
}

