import React from "react";
import { Environment } from '../../environments/environment';
import store from '../../Store';
import { AMOUNT_TO_PAY } from '../../actions/AmountToPay';
import {LocalStorageManager} from "../../services/LocalStorageManager";

export class ProductElement extends React.Component {
    constructor(props){
        super(props);

        let picture;

        if (props.product.productCategory && props.product.productCategory.picto) {
            picture = Environment.get().API_BASE.concat(props.product.productCategory.picto);
        } else {
            picture = process.env.PUBLIC_URL + '/food.png';
        }

        this.state = {
            id: props.row,
            picture: picture,
            name: props.product.name,
            price: props.product.price,
            isChoosen: this.checkIsChoosen(props.row),
            selected: false
        };

        store.subscribe(() => {
            if(store.getState().amountToPay.action === 'TOTAL'){
                this.setState({selected:false});
            }
        });
    }

    checkIsChoosen(id) {
        const dataStore = new LocalStorageManager('app');
        const choosenProducts = dataStore.get('CHOOSEN_PRODUCTS_NOTE', "");
        const cps = choosenProducts.split(',');

        for (let i = 0; i < cps.length; ++i) {
            const val = parseInt(cps[i], 10);

            if (val === parseInt(id, 10)) {
                return true;
            }
        }

        return false;
    }

    handleClickSelectProduct() {
        if (!this.state.isChoosen) {
            const dataStore = new LocalStorageManager('app');
            const choosen = dataStore.get('CHOOSEN_PRODUCTS', []);
            const amount = this.state.price;
            const newChoosen = [];

            for (let i = 0; i < choosen.length; ++i) {
                const val = choosen[i];

                if (val !== this.state.id) {
                    newChoosen.push(val);
                }
            }

            if (!this.state.selected) {
                this.setState({selected: true});
                newChoosen.push(this.state.id);
                dataStore.set('CHOOSEN_PRODUCTS', newChoosen);
                store.dispatch({ type: AMOUNT_TO_PAY, amount, action: 'CHOOSEN_PRODUCTS' });
            } else {
                this.setState({selected: false});
                dataStore.set('CHOOSEN_PRODUCTS', newChoosen);
                store.dispatch({ type: AMOUNT_TO_PAY, amount, action: 'CHOOSEN_PRODUCTS' });
            }
        }
    }

    render() {
        let selected = this.state.selected ? 'rgba(105, 192, 172, 0.2)' : 'white';

        if (this.state.isChoosen) {
            selected = 'rgb(220,189,189)';
        }

        return(
            <div className="product-element" onClick={this.handleClickSelectProduct.bind(this)} style={{'background-color': selected}}>
                <span className="product-picture">
                    <img src={this.state.picture} alt="Produit"/>
                </span>
                <span className="product-name">{this.state.name}</span>
                <span className="product-quantity">x1</span>
                <span className="product-price"><strong>{Number(this.state.price).toFixed(2)} €</strong></span>
            </div>
        );
    }
}
